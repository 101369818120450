module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Taneční studio Kamily Hlaváčikové","short_name":"TSKH Most","start_url":"/","background_color":"#b508ff","theme_color":"#444","display":"standalone","icon":"src/images/brand/logo.svg","shortcuts":[{"name":"Vystoupení a akce","url":"/vystoupeni"},{"name":"Rezervační systém","url":"/rezervace"},{"name":"Administrace","url":"/admin"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e8fc3912b8a31a2005cc8608b82a434b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["cs","en"],"defaultLanguage":"cs","siteUrl":"https://preview.tanecnistudio-kh.cz/","keySeparator":".","nsSeparator":":","fallbackNS":false,"i18nextOptions":{"lowerCaseLng":true,"keySeparator":".","contextSeparator":"","nsSeparator":":","defaultNS":"core","fallbackNS":false}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-aktualne-tsx": () => import("./../../../src/pages/admin/aktualne.tsx" /* webpackChunkName: "component---src-pages-admin-aktualne-tsx" */),
  "component---src-pages-admin-editor-tsx": () => import("./../../../src/pages/admin/editor.tsx" /* webpackChunkName: "component---src-pages-admin-editor-tsx" */),
  "component---src-pages-admin-export-tsx": () => import("./../../../src/pages/admin/export.tsx" /* webpackChunkName: "component---src-pages-admin-export-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-konfigurace-dalsi-tsx": () => import("./../../../src/pages/admin/konfigurace/dalsi.tsx" /* webpackChunkName: "component---src-pages-admin-konfigurace-dalsi-tsx" */),
  "component---src-pages-admin-konfigurace-index-tsx": () => import("./../../../src/pages/admin/konfigurace/index.tsx" /* webpackChunkName: "component---src-pages-admin-konfigurace-index-tsx" */),
  "component---src-pages-admin-konfigurace-kontakty-tsx": () => import("./../../../src/pages/admin/konfigurace/kontakty.tsx" /* webpackChunkName: "component---src-pages-admin-konfigurace-kontakty-tsx" */),
  "component---src-pages-admin-konfigurace-oteviraci-doba-tsx": () => import("./../../../src/pages/admin/konfigurace/oteviraci-doba.tsx" /* webpackChunkName: "component---src-pages-admin-konfigurace-oteviraci-doba-tsx" */),
  "component---src-pages-admin-konfigurace-saly-tsx": () => import("./../../../src/pages/admin/konfigurace/saly.tsx" /* webpackChunkName: "component---src-pages-admin-konfigurace-saly-tsx" */),
  "component---src-pages-admin-konfigurace-skolni-rok-tsx": () => import("./../../../src/pages/admin/konfigurace/skolni-rok.tsx" /* webpackChunkName: "component---src-pages-admin-konfigurace-skolni-rok-tsx" */),
  "component---src-pages-admin-kurzy-tsx": () => import("./../../../src/pages/admin/kurzy.tsx" /* webpackChunkName: "component---src-pages-admin-kurzy-tsx" */),
  "component---src-pages-admin-lide-archiv-tsx": () => import("./../../../src/pages/admin/lide/archiv.tsx" /* webpackChunkName: "component---src-pages-admin-lide-archiv-tsx" */),
  "component---src-pages-admin-lide-index-tsx": () => import("./../../../src/pages/admin/lide/index.tsx" /* webpackChunkName: "component---src-pages-admin-lide-index-tsx" */),
  "component---src-pages-admin-lide-lektori-tsx": () => import("./../../../src/pages/admin/lide/lektori.tsx" /* webpackChunkName: "component---src-pages-admin-lide-lektori-tsx" */),
  "component---src-pages-admin-lide-ucty-tsx": () => import("./../../../src/pages/admin/lide/ucty.tsx" /* webpackChunkName: "component---src-pages-admin-lide-ucty-tsx" */),
  "component---src-pages-admin-napoveda-tsx": () => import("./../../../src/pages/admin/napoveda.tsx" /* webpackChunkName: "component---src-pages-admin-napoveda-tsx" */),
  "component---src-pages-admin-nastaveni-choreografie-tsx": () => import("./../../../src/pages/admin/nastaveni/choreografie.tsx" /* webpackChunkName: "component---src-pages-admin-nastaveni-choreografie-tsx" */),
  "component---src-pages-admin-nastaveni-hlavni-stranka-tsx": () => import("./../../../src/pages/admin/nastaveni/hlavni-stranka.tsx" /* webpackChunkName: "component---src-pages-admin-nastaveni-hlavni-stranka-tsx" */),
  "component---src-pages-admin-nastaveni-index-tsx": () => import("./../../../src/pages/admin/nastaveni/index.tsx" /* webpackChunkName: "component---src-pages-admin-nastaveni-index-tsx" */),
  "component---src-pages-admin-nastaveni-kurzy-tsx": () => import("./../../../src/pages/admin/nastaveni/kurzy.tsx" /* webpackChunkName: "component---src-pages-admin-nastaveni-kurzy-tsx" */),
  "component---src-pages-admin-nastaveni-skupiny-tsx": () => import("./../../../src/pages/admin/nastaveni/skupiny.tsx" /* webpackChunkName: "component---src-pages-admin-nastaveni-skupiny-tsx" */),
  "component---src-pages-admin-nastaveni-sponzori-tsx": () => import("./../../../src/pages/admin/nastaveni/sponzori.tsx" /* webpackChunkName: "component---src-pages-admin-nastaveni-sponzori-tsx" */),
  "component---src-pages-admin-nastroje-cache-tsx": () => import("./../../../src/pages/admin/nastroje/cache.tsx" /* webpackChunkName: "component---src-pages-admin-nastroje-cache-tsx" */),
  "component---src-pages-admin-nastroje-index-tsx": () => import("./../../../src/pages/admin/nastroje/index.tsx" /* webpackChunkName: "component---src-pages-admin-nastroje-index-tsx" */),
  "component---src-pages-admin-nastroje-mask-tsx": () => import("./../../../src/pages/admin/nastroje/mask.tsx" /* webpackChunkName: "component---src-pages-admin-nastroje-mask-tsx" */),
  "component---src-pages-admin-nastroje-prispevky-tsx": () => import("./../../../src/pages/admin/nastroje/prispevky.tsx" /* webpackChunkName: "component---src-pages-admin-nastroje-prispevky-tsx" */),
  "component---src-pages-admin-nastroje-sloucit-tsx": () => import("./../../../src/pages/admin/nastroje/sloucit.tsx" /* webpackChunkName: "component---src-pages-admin-nastroje-sloucit-tsx" */),
  "component---src-pages-admin-rozvrh-hodin-tsx": () => import("./../../../src/pages/admin/rozvrh-hodin.tsx" /* webpackChunkName: "component---src-pages-admin-rozvrh-hodin-tsx" */),
  "component---src-pages-admin-spravce-souboru-index-tsx": () => import("./../../../src/pages/admin/spravce-souboru/index.tsx" /* webpackChunkName: "component---src-pages-admin-spravce-souboru-index-tsx" */),
  "component---src-pages-admin-uspechy-tsx": () => import("./../../../src/pages/admin/uspechy.tsx" /* webpackChunkName: "component---src-pages-admin-uspechy-tsx" */),
  "component---src-pages-admin-vystoupeni-tsx": () => import("./../../../src/pages/admin/vystoupeni.tsx" /* webpackChunkName: "component---src-pages-admin-vystoupeni-tsx" */),
  "component---src-pages-admin-zpetna-vazba-tsx": () => import("./../../../src/pages/admin/zpetna-vazba.tsx" /* webpackChunkName: "component---src-pages-admin-zpetna-vazba-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-informace-bankovni-spojeni-tsx": () => import("./../../../src/pages/informace/bankovni-spojeni.tsx" /* webpackChunkName: "component---src-pages-informace-bankovni-spojeni-tsx" */),
  "component---src-pages-informace-cenik-tsx": () => import("./../../../src/pages/informace/cenik.tsx" /* webpackChunkName: "component---src-pages-informace-cenik-tsx" */),
  "component---src-pages-informace-gdpr-tsx": () => import("./../../../src/pages/informace/gdpr.tsx" /* webpackChunkName: "component---src-pages-informace-gdpr-tsx" */),
  "component---src-pages-informace-o-webu-tsx": () => import("./../../../src/pages/informace/o-webu.tsx" /* webpackChunkName: "component---src-pages-informace-o-webu-tsx" */),
  "component---src-pages-informace-partneri-tsx": () => import("./../../../src/pages/informace/partneri.tsx" /* webpackChunkName: "component---src-pages-informace-partneri-tsx" */),
  "component---src-pages-informace-pravidla-tsx": () => import("./../../../src/pages/informace/pravidla.tsx" /* webpackChunkName: "component---src-pages-informace-pravidla-tsx" */),
  "component---src-pages-informace-prohlaseni-tsx": () => import("./../../../src/pages/informace/prohlaseni.tsx" /* webpackChunkName: "component---src-pages-informace-prohlaseni-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kurzy-individualni-tsx": () => import("./../../../src/pages/kurzy/individualni.tsx" /* webpackChunkName: "component---src-pages-kurzy-individualni-tsx" */),
  "component---src-pages-kurzy-pro-deti-tsx": () => import("./../../../src/pages/kurzy/pro-deti.tsx" /* webpackChunkName: "component---src-pages-kurzy-pro-deti-tsx" */),
  "component---src-pages-kurzy-pro-dospele-tsx": () => import("./../../../src/pages/kurzy/pro-dospele.tsx" /* webpackChunkName: "component---src-pages-kurzy-pro-dospele-tsx" */),
  "component---src-pages-o-nas-choreografie-tsx": () => import("./../../../src/pages/o-nas/choreografie.tsx" /* webpackChunkName: "component---src-pages-o-nas-choreografie-tsx" */),
  "component---src-pages-o-nas-lektori-tsx": () => import("./../../../src/pages/o-nas/lektori.tsx" /* webpackChunkName: "component---src-pages-o-nas-lektori-tsx" */),
  "component---src-pages-o-nas-napsali-o-nas-tsx": () => import("./../../../src/pages/o-nas/napsali-o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-napsali-o-nas-tsx" */),
  "component---src-pages-o-nas-nase-uspechy-tsx": () => import("./../../../src/pages/o-nas/nase-uspechy.tsx" /* webpackChunkName: "component---src-pages-o-nas-nase-uspechy-tsx" */),
  "component---src-pages-o-nas-o-tanecnim-studiu-tsx": () => import("./../../../src/pages/o-nas/o-tanecnim-studiu.tsx" /* webpackChunkName: "component---src-pages-o-nas-o-tanecnim-studiu-tsx" */),
  "component---src-pages-o-nas-stipendia-tsx": () => import("./../../../src/pages/o-nas/stipendia.tsx" /* webpackChunkName: "component---src-pages-o-nas-stipendia-tsx" */),
  "component---src-pages-o-nas-zazemi-tsx": () => import("./../../../src/pages/o-nas/zazemi.tsx" /* webpackChunkName: "component---src-pages-o-nas-zazemi-tsx" */),
  "component---src-pages-obnoveni-hesla-tsx": () => import("./../../../src/pages/obnoveni-hesla.tsx" /* webpackChunkName: "component---src-pages-obnoveni-hesla-tsx" */),
  "component---src-pages-omluvenky-tsx": () => import("./../../../src/pages/omluvenky.tsx" /* webpackChunkName: "component---src-pages-omluvenky-tsx" */),
  "component---src-pages-prihlaska-index-tsx": () => import("./../../../src/pages/prihlaska/index.tsx" /* webpackChunkName: "component---src-pages-prihlaska-index-tsx" */),
  "component---src-pages-prihlaska-tisk-tsx": () => import("./../../../src/pages/prihlaska/tisk.tsx" /* webpackChunkName: "component---src-pages-prihlaska-tisk-tsx" */),
  "component---src-pages-pro-verejnost-animacni-programy-tsx": () => import("./../../../src/pages/pro-verejnost/animacni-programy.tsx" /* webpackChunkName: "component---src-pages-pro-verejnost-animacni-programy-tsx" */),
  "component---src-pages-pro-verejnost-bazar-tsx": () => import("./../../../src/pages/pro-verejnost/bazar.tsx" /* webpackChunkName: "component---src-pages-pro-verejnost-bazar-tsx" */),
  "component---src-pages-pro-verejnost-hostesky-roztleskavacky-tsx": () => import("./../../../src/pages/pro-verejnost/hostesky-roztleskavacky.tsx" /* webpackChunkName: "component---src-pages-pro-verejnost-hostesky-roztleskavacky-tsx" */),
  "component---src-pages-pro-verejnost-nabidka-vystoupeni-tsx": () => import("./../../../src/pages/pro-verejnost/nabidka-vystoupeni.tsx" /* webpackChunkName: "component---src-pages-pro-verejnost-nabidka-vystoupeni-tsx" */),
  "component---src-pages-pro-verejnost-skoly-skolky-tsx": () => import("./../../../src/pages/pro-verejnost/skoly-skolky.tsx" /* webpackChunkName: "component---src-pages-pro-verejnost-skoly-skolky-tsx" */),
  "component---src-pages-pro-verejnost-soupravy-tsx": () => import("./../../../src/pages/pro-verejnost/soupravy.tsx" /* webpackChunkName: "component---src-pages-pro-verejnost-soupravy-tsx" */),
  "component---src-pages-rezervace-index-tsx": () => import("./../../../src/pages/rezervace/index.tsx" /* webpackChunkName: "component---src-pages-rezervace-index-tsx" */),
  "component---src-pages-rozvrh-hodin-tsx": () => import("./../../../src/pages/rozvrh-hodin.tsx" /* webpackChunkName: "component---src-pages-rozvrh-hodin-tsx" */),
  "component---src-pages-ucet-tsx": () => import("./../../../src/pages/ucet.tsx" /* webpackChunkName: "component---src-pages-ucet-tsx" */),
  "component---src-pages-ukazky-tsx": () => import("./../../../src/pages/ukazky.tsx" /* webpackChunkName: "component---src-pages-ukazky-tsx" */),
  "component---src-pages-vystoupeni-tsx": () => import("./../../../src/pages/vystoupeni.tsx" /* webpackChunkName: "component---src-pages-vystoupeni-tsx" */)
}


import { Provider } from "react-redux";
import React from "react";
import { SinkFactory } from "react-redux-sink";
import { composeWithDevTools } from "redux-devtools-extension";

const store = SinkFactory.createStore({
	useTrigger: true,
	devToolOptions: {
		devToolCompose: composeWithDevTools,
		disabled: process.env.GATSBY_ENV !== "development",
	},
});

export default ({ element }: any, options: any) => {
	return <Provider store={store}>{element}</Provider>;
};
